import Typography from "typography"
import Wordpress2016 from "typography-theme-wordpress-2016"

Wordpress2016.overrideThemeStyles = () => {
  return {
    "a.gatsby-resp-image-link": {
      boxShadow: `none`,
    },
    "h1": {
      fontFamily: `Montserrat`,
      fontWeight: '300',
      marginBottom: '20'
    },
    "h3": {
      fontFamily: `Montserrat`,
      fontWeight: '300'
    },
    "h2": {
      fontFamily: 'Montserrat',
      fontWeight: '300',
    },
    ".menuLinks": {
      display: "inline",
      marginBottom: "40px"
    },
    ".menuLinks a": {
      color: "#222831",
      marginRight: '15px',
      fontSize: '18px',
      fontWeight: '400',
      fontFamily: 'Montserrat',
    },
    "header":{
      marginBottom: '40px'
    },
    "main header h1": {
      fontFamily: "Merriweather",
      fontWeight: '300',
    },
    ".icons .dark":{
      color: "#eeeeee",
    },

    // LIGHT THEME

    "body":{
      backgroundColor: "#EEEEEE",
      color: "#222831"
    },
    "a":{
      color: "#ff5722"
    },
    "h3 a": {
      color: "#ff5722"
    },

    // DARK THEME

    "body.dark": {
      backgroundColor: "#232931",
      color: "#eeeeee"
    },
    "body.dark a": {
      color: "#ff5722"
    },
    "h1.dark": {
      color: "#207a82"
    },
    "body.dark blockquote p": {
      color: "#eeeeee",
    },
    "body.dark blockquote ": {
      borderColor: "#eeeeee"
    },

    ".button-switcher": {
      float: "right",
      marginTop: "27px",
      background: "rgb(255, 87, 34, 0.2) !important"
    },
    ".button-switcher::after": {
      background: "#ff5722 !important"
    },
    ".gatsby-resp-image-image":{
      marginTop: "10px !important"
    }
  }
}

delete Wordpress2016.googleFonts

const typography = new Typography(Wordpress2016)

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
