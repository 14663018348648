import React from "react"
import { Link } from "gatsby"
import { ThemeToggler } from 'gatsby-plugin-dark-mode'

import { rhythm, scale } from "../utils/typography"

import moon from "../../content/assets/moon.png"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  let header

  if (location.pathname === rootPath) {
    header = (
      <div>
        <div style={{
          display: "inline-block",
          width: "100%",
          height: "40px",
        }}>
            <h1
            style={{
              ...scale(1.5),
              marginTop: 0,
              fontSize: 18,
              fontWeight: 600,
              float: "left",
            }}
          >
            <Link
              style={{
                boxShadow: `none`,
                color: `inherit`,
              }}
              to={`/`}
            >
              Андрей Осипенко / <span style={{color: "#ff5722"}}>Блог</span>
            </Link>
          </h1>

          <ThemeToggler>
            {({ theme, toggleTheme }) => (
              <img src={moon} style={{float: "right", marginTop: 27, width: 35}} onClick={() => {
                const nextTheme = theme === 'light' ? 'dark' : 'light'
                toggleTheme(nextTheme)
              }}/>
            )}
        </ThemeToggler>
        </div>
        <div className="menuLinks">
        <Link
              style={{
                boxShadow: `none`,
                color: `inherit`,
              }}
              to={`/`}
            >Блог</Link>
         <a
            style={{
              boxShadow: `none`,
              color: `inherit`,
            }}
            href='https://redcamp.pro'
            target="_blank"
          >Курсы PM</a>
        </div>
      </div>
    )
  } else {
    header = (
      <div>
        <div style={{
          display: "inline-block",
          width: "100%",
          height: "40px",
        }}>
            <h1
            style={{
              ...scale(1.5),
              marginTop: 0,
              fontSize: 18,
              fontWeight: 600,
              float: "left",
            }}
          >
            <Link
              style={{
                boxShadow: `none`,
                color: `inherit`,
              }}
              to={`/`}
            >
              Андрей Осипенко / <span style={{color: "#ff5722"}}>Блог</span>
            </Link>
          </h1>

          <ThemeToggler>
            {({ theme, toggleTheme }) => (
              <img src={moon} style={{float: "right", marginTop: 27,}} onClick={() => {
                const nextTheme = theme === 'light' ? 'dark' : 'light'
                toggleTheme(nextTheme)
              }}/>
            )}
        </ThemeToggler>
        </div>
        <div className="menuLinks">
        <Link
              style={{
                boxShadow: `none`,
                color: `inherit`,
              }}
              to={`/`}
            >Блог</Link>
        <a
            style={{
              boxShadow: `none`,
              color: `inherit`,
            }}
            href='https://redcamp.pro'
            target="_blank"
          >Курсы PM</a>
        </div>
      </div>
    )
  }
  return (
    <div
      style={{
        marginLeft: `auto`,
        marginRight: `auto`,
        maxWidth: rhythm(24),
        paddingLeft: 10,
        paddingRight: 10,
      }}
    >
      <header>{header}</header>
      <main>{children}</main>
      <footer>
        © {new Date().getFullYear()}, Built with
        {` `}
        ❤️
      </footer>
    </div>
  )
}

export default Layout
